$(function(){
  $('.fil-text table')
    .removeClass('stack')
    .wrap('<div class="table-scroll">');

  //make it display as gds3 table component.
  //handle table component and table in textarea component.
  const $tableInText = $('.fil-text .table-scroll table');
  $tableInText.parent().addClass('fil-table-wrapper');
  const $tables = $('.fil-tables.fil-table-wrapper table').add($tableInText);

  if($tables.length){
    $tables.each(function(){
      const $table = $(this);
      $table.addClass('fil-table fil-table--responsive');
      const $columns = $table.find('thead th');

      $table.removeAttr('border cellpadding cellspacing');
      $table.find('tbody tr').each(function(){
        $(this).find('td').each(function(index){
          const className = index == 0 ? 'fil-table-row--mobile-header' : 'fil-table-row--column';
          $(this).addClass(className);
          $(this).attr('data-heading', $columns.eq(index).html());
          $(this).wrapInner('<div class="fil-table-row-selector"><div></div></div>');
        });
      });
    });
  }
});
