import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

const DropdownMenu = require('./header-dropdown-menu');
class HeaderBase {
  constructor(gdsFoundation) {
    this.foundation = gdsFoundation;
    this.navbar = $('.elastic-header');
    this.windowTopPosition = 0;
    this.outlookSubMenuWrapper = $('.outlook-layout-submenu__wrapper');
    this.outlookSubMenuContainer = $('.outlook-layout-submenu__container');
  }

  init() {
    this.clickTierOne();
    this.toggleSearch();
    this.activateTierThree();
    this.deactivateTierThree();
    this.toggleOverflowLink();
    this.toggleMenu();
    this.openSubMenu();
    this.closeSubMenu();
    this.closeTierThreeMenu();
    this.toggleLogin();
    this.toggleLogout();
    this.togglePersonalInvstingTab();
    this.toggleLanguageSelectorTab();

    this.setupMenu();

    this.toggleScrollUp();

    this.tierThreeDropDown = new DropdownMenu(
      '.tier-three-bar .drop-down-menu-container',
      this.foundation
    );

    this.outlookSubnav();
  }

  clickTierOne() {
    $('.tier-one').on('click', function() {
      if ($(this).hasClass('active-megamenu')) {
        $('.active-megamenu').removeClass('active-megamenu');
        $('.ancestor').removeClass('active-ancestor');
      } else {
        $('.active-megamenu').removeClass('active-megamenu');
        $(this).addClass('active-megamenu');
        if (!$(this).hasClass('ancestor')) {
          $('.ancestor').addClass('active-ancestor');
        } else {
          $('.ancestor').removeClass('active-ancestor');
        }
      }

      $('.tier-three').removeClass('active');
      $('.overflow-link.active').removeClass('active');
    });
  }

  toggleSearch() {
    $('.close-megamenu, .backdrop, .toggle-search').on('click touch', function() {
      $('.active-megamenu').removeClass('active-megamenu');
      $('.ancestor').removeClass('active-ancestor');
      $('.tier-three').removeClass('active');
    });
  }

  activateTierThree() {
    $('.tier-three-link').on('click touch', function() {
      $(this)
        .next()
        .addClass('active');
    });
  }

  deactivateTierThree() {
    $('.tier-three-return').on('click touch', function() {
      $(this)
        .parent()
        .removeClass('active');
    });
  }

  toggleOverflowLink() {
    $('.overflow-link').on('click touch', function() {
      $(this).toggleClass('active');
    });
  }

  // Mobile menu button events. These are hidden on large screens so no
  // problem to register event listeners.
  toggleMenu() {
    $('.menu-toggle').on('click touch', function() {
      const $mainHeader = $('.main-header');
      const $this = $(this);
      const isMenuOpened = $this.hasClass('is-open');

      if($this.hasClass('login') || $this.hasClass('logout')) {
        return false;
      }

      $mainHeader.toggleClass('is-open', !isMenuOpened);
      $('.active-megamenu').removeClass('active-megamenu');

      let otherMenuToggler = $('.menu-toggle.is-open')[0];
      if (otherMenuToggler && otherMenuToggler !== this) {
        $(otherMenuToggler).removeClass('is-open');
      }

      $this.toggleClass('is-open');

      if ($mainHeader.hasClass('is-sub-open')) {
        $mainHeader.removeClass('is-sub-open');
        $('.megamenu .category-body.active').removeClass('active');
      }
    });
  }

  openSubMenu() {
    $('.category .category-header').on('click touch', function() {
      $(this)
        .next('.category-body')
        .addClass('active')
        .parents('header.main-header')
        .addClass('is-sub-open');
    });
  }

  closeSubMenu() {
    $('.category-body-return').on('click touch', function() {
      $(this)
        .closest('.category-body')
        .removeClass('active')
        .closest('header.main-header')
        .removeClass('is-sub-open');

      $('body').removeClass('is-mobile is-sub-open');
    });
  }

  closeTierThreeMenu() {
    $('.tier-three-return').on('click touch', function() {
      $(this)
        .closest('.tier-three')
        .removeClass('active');
    });
  }

  toggleLogin() {
    $('.fil-header .login').on('click touch', function() {
      let $loginLink = $('#globalSection .wfb-nav:not(.logged-in) .login');
      if ($loginLink.length) {
        $loginLink[0].click();
      }
    });
  }

  toggleLogout() {
    $('.fil-header .logout').on('click touch', function() {
      let $logoutLink = $('#globalSection .wfb-nav.logged-in .logout');
      if ($logoutLink.length) {
        $logoutLink[0].click();
      }
    });
  }

  /**
   * @description - Global nav drop down onClick functions
   */
  togglePersonalInvstingTab() {
    $('.sub-menu-trigger').on('click touch', function() {
      $('.personal-investing-tab').toggleClass('is-open');
    });
  }

  /**
   * @description - Language drop down onClick functions
   */
  toggleLanguageSelectorTab() {
    $('.language-selector-trigger').on('click touch', function() {
      $('.language-selector-tab').toggleClass('is-open');
    });
  }

  /**
   * Setup menu for mobile layout
   *
   * Move wayfinding bar inside of the mobile menu and ensure it is displayed.
   */
  setupMobileMenu() {
    $('.global-section').insertAfter('.fil-header .main-menu .menu');

    $('.global-section').css('display', 'block');
    if ($('#globalSection .wfb-nav .login')[0]) {
      $('.fil-header .nav-bar .login').removeClass('login-disabled');
    } else {
      $('.fil-header .nav-bar .login').addClass('login-disabled');
    }
  }
  /**
   * Setup menu for desktop layout
   *
   * Move wayfinding bar outside of and above the menu and ensure it is
   * displayed.
   */
  setupDesktopMenu() {
    $('.global-section').prependTo('#body-container > main');
    $('.global-section').css('display', 'block');
  }

  toggleScrollUp() {
    if ($(this.navbar).length > 0) {
      $(window).scroll(function() {
        const scrollTop = $(window).scrollTop();
        const itemHeight = $(this.navbar).height();
        if (this.windowTopPosition < scrollTop && scrollTop > itemHeight * 2) {
          $(this.navbar).addClass('scrollUp');
        } else if (this.windowTopPosition > scrollTop && scrollTop > itemHeight) {
          $(this.navbar).removeClass('scrollUp');
        }
        this.windowTopPosition = scrollTop;
      });
    }
  }

  outlookSubnav() {
    if (this.outlookSubMenuWrapper.length) {
      this.subMenuContainerScrollLeft();
      $(window).on('orientationchange', this.subMenuContainerScrollLeft());
    }
  }

  // Scroll the outlook subnav to the selected/active node
  subMenuContainerScrollLeft() {
    let totalWidth = this.outlookSubMenuWrapper.outerWidth();
    this.outlookSubMenuContainer.css('width', totalWidth);
    let $selectedItem = this.outlookSubMenuContainer.find('.selected').parent();

    let myScrollPos =
      $selectedItem.offset().left +
      $selectedItem.outerWidth(true) / 2 +
      this.outlookSubMenuContainer.scrollLeft() -
      this.outlookSubMenuContainer.width() / 2;

    this.outlookSubMenuContainer.scrollLeft(myScrollPos);
  }

  setupMenu() {
    /**
     * Detect and handle switch between mobile and desktop breakpoints
     */
    $(window).on('changed.zf.mediaquery', () => {
      if (MediaQuery.atLeast('large')) {
        this.setupDesktopMenu();
      } else {
        this.setupMobileMenu();
      }
    });

    /**
     * Desktop is the default layout. On initial load, check the current
     * breakpoint and setup the mobile menu if required.
     */
    if (window.innerWidth < 1024) {
      this.setupMobileMenu();
    }
  }
}

module.exports = HeaderBase;
