// import required modules for cms content
import { Foundation } from 'foundation-sites/js/foundation.core';
import { Magellan } from 'foundation-sites/js/foundation.magellan';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { SmoothScroll } from 'foundation-sites/js/foundation';
import $ from 'jquery';
//import "@fil-global/gds-webcomponents/fil-icon/fil-icon.component";

// global foundation setup
Foundation.addToJquery($);
window.jQuery = window.$ = $;

// initialise any relevant foundation objects
window.addEventListener('DOMContentLoaded', (event) => {
  Array.from(document.querySelectorAll('[data-glossary]')).forEach(
    (magellan) => {
      new Magellan($(magellan));
    }
  );

  Array.from(document.querySelectorAll('[data-smooth-scroll]')).forEach(
    (elem) => {
      new SmoothScroll($(elem));
    }
  );

  // add any additional functionality to added components
  const modalTrigger = document.querySelector('#test-modal');
  if (modalTrigger) {
    modalTrigger.addEventListener('click', (e) => {
      e.stopPropagation();
      const modal = document.querySelector('fil-modal');
      modal.active = true;
    });
  }
});
