import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

$(function() {
  const $images = $('.fil-article-page .fil-text__body p > img.align-left, .fil-article-page .fil-image img.align-left');
  function adjustImages() {
    $images.each(function(){
      const $imgElem = $(this);
      const $imgContainer = $imgElem.parent().css({
        'position': 'relative'
      }).addClass('sticky-img-wrapper');

      if (MediaQuery.atLeast('large')) {
        $imgElem.closest('.medium-10.medium-centered').css('width', '100%');
        const imgLeft = $imgContainer.offset().left;

        $imgElem.css({
          'left': -imgLeft,
          'width': $imgContainer.width() + imgLeft,
          'position': 'relative',
          'max-width': 'none'
        });

        //IE11 will ignore `calc()`, but we need it for chrome.
        $imgElem.css('width', `calc(100% + ${imgLeft}px)`);
      } else {
        $imgElem.attr('style','').css('width', '100%');
      }
    });
  }

  $(window).on('changed.zf.mediaquery', () => {
    adjustImages();
  });

  adjustImages();
});
