import { bindTaggingEventOnWebComponent, TAGGING_EVENT_MAP } from '_fil/fil-global-frontend-gds3/adobe_track';

//bind hero homepage
bindTaggingEventOnWebComponent('fil-hero-home', [
  {
    'validator': node => node.classList && node.classList.contains('fil-button--primary'),
    'options': TAGGING_EVENT_MAP['HERO-HOMEPAGE']['MAIN_CTA']
  }, {
    'validator': (node) => node.tagName && node.tagName.toLowerCase() == 'fil-hero-block-item',
    'get_options': node => {
      const itemId = node.getAttribute('item-id');
      const defaultOptions = TAGGING_EVENT_MAP['HERO-HOMEPAGE']['BOTTOM_CTA'];
      if (itemId) {
        return { ...defaultOptions, 'event_content': `cta_${itemId}` };
      }
    }
  }
]);
