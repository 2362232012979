const ISSArticleConstants = {
  ARTICLE_API_URL: '/iss-article/data/',
  SUBCATEGORY_TAG_LABEL: 'article-type',
  TEASER_VIEW_ARTICLE_NUMBER: 9,
  ADDED_ARTICLE_NUMBER: 3
};

export class ISSArticleTagFilters {
  constructor(rootElement) {
    this.$root = $('.tag-filters', rootElement);
    this.$articleNumberDOM = this.$root.find('.article-number-in');
    this.$resetButton = this.$root.find('.clear-filter-button');
    this.$tagContainer = this.$root.find('.tag-row-container');

    this.initStates();
    this.trackEvents();
  }

  initStates() {
    const checkedTags = {};
    const cats = {};
    const subcategoryTagMap = {};
    const initialTags = window.filtered_article_selected_tags;

    this.tagStates = {
      checked: checkedTags,
      cats: cats,
      tagArticleMap: [],
      subcategoryTagMap: subcategoryTagMap
    };

    this.filterStates = {
      orders: [],
    };

    this.$tagContainer.find('.tag-row').each((_, tagRow) => {
      const catLabel = $(tagRow).attr('data-tag-cat-label');
      cats[catLabel] = [];
      checkedTags[catLabel] = [];

      $(tagRow).find('.chip').each((_, chip) => {
        const $chip = $(chip);
        const tagSlug = $chip.attr('data-chip-slug');

        cats[catLabel].push({ label: tagSlug, enabled: true });
        if (initialTags.includes(tagSlug)) {
          checkedTags[catLabel].push(tagSlug);
          this.updateFilter(catLabel, true);
        }

        if (catLabel == ISSArticleConstants.SUBCATEGORY_TAG_LABEL) {
          const tagLabel = $chip.text().trim();
          subcategoryTagMap[tagLabel] = tagSlug;
        }
      });
    });

    this.parseLocationHash();
    this.fireOnTagStateUpdated();
  }

  getSubcategoryTagMap() {
    return this.tagStates.subcategoryTagMap;
  }

  resetAllFilters() {
    const checkedTags = this.tagStates.checked;
    const catTags = this.tagStates.cats;
    const tagArticleMap = this.tagStates.tagArticleMap;

    for (const catLabel in checkedTags) {
      checkedTags[catLabel] = [];
    }

    $.each(catTags, (_, tags) => {
      tags.forEach(tag => {
        tag.enabled = tag.label in tagArticleMap;
      });
    });

    this.filterStates = {
      orders: [],
    };
  }

  updateFilter(catLabel, isChecked) {
    const filterOrders = this.filterStates.orders;
    const checkedTags = this.tagStates.checked[catLabel];

    //reset all
    if (catLabel === true) {
      this.resetAllFilters();
    } else if (catLabel) {
      if (isChecked) {
        if (!filterOrders.includes(catLabel)) {
          filterOrders.push(catLabel);
        }
      } else {
        if (checkedTags.length == 0) {
          const filterPos = filterOrders.indexOf(catLabel);
          if (filterPos != -1) {
            filterOrders.splice(filterPos, 1);
          }
        }
      }
    }
  }

  updateLocationHash() {
    const checkedTags = this.tagStates.checked;
    const hashTagMaps = [];

    for (const catLabel in checkedTags) {
      const tags = checkedTags[catLabel];
      if (tags.length) {
        hashTagMaps.push([catLabel, tags.join(',')]);
      }
    }

    const tagHash = hashTagMaps.map(map => `${map[0]}=${map[1]}`).join('&');
    history.pushState({}, '', `#${tagHash}`);
  }

  filterArticleList() {
    const filteredArticleList = this.filterStates.filteredArticleList;

    if (filteredArticleList) {
      this.updateArticleNumber(filteredArticleList.length);
    }

    if (this.articleManager) {
      this.articleManager.setVisible(filteredArticleList);
    }
  }

  renderTags() {
    const checkedTags = this.tagStates.checked;
    const $chips = this.$tagContainer.find('.chip').removeClass('checked');
    const catTags = this.tagStates.cats;
    let disabledTags = [];

    for (const catLabel in checkedTags) {
      const tags = checkedTags[catLabel];
      if (tags.length > 0) {
        $chips.filter(tags.map(tag => `[data-chip-slug="${tag}"]`).join(',')).addClass('checked');
      }
    }

    $.each(catTags, (_, tags) => {
      disabledTags = disabledTags.concat(tags.filter(tag => !tag.enabled));
    });

    $chips.removeAttr('disabled')
      .filter(
        disabledTags.map(tag => `[data-chip-slug="${tag.label}"]`).join(',')
      ).attr('disabled', true);
  }

  updateArticleNumber(number) {
    this.$articleNumberDOM.html(number);
  }

  loadArticles(articles) {
    this.articles = articles;
    this.updateTagArticleMap(articles);
    this.updateArticleNumber(articles.length);
    this.fireOnTagStateUpdated();
  }

  fireOnTagStateUpdated() {
    this.updateDisabledTags();
    this.toggleStates();
    this.filterArticleList();
    this.renderTags();
  }

  toggleStates() {
    const hasFilter = this.filterStates.orders.length > 0;
    this.$resetButton.toggleClass('hide', !hasFilter);
    this.$articleNumberDOM.parent().toggleClass('hide', !hasFilter);
  }

  updateDisabledTags() {
    const catTags = this.tagStates.cats;
    const allCheckedTags = this.tagStates.checked;
    const allArticles = this.articles;
    const tagArticleMap = this.tagStates.tagArticleMap;

    if (allArticles) {
      let filteredArticles = allArticles;
      const filteredCat = {};

      if (this.filterStates.orders.length == 0) {
        //reset all.
        this.updateFilter(true);
      } else {
        this.filterStates.orders.forEach((curFilterCat, id) => {
          const checkedTags = allCheckedTags[curFilterCat];

          if (checkedTags.length) {
            filteredArticles = filteredArticles.filter(article => article.tags.filter(tag => checkedTags.includes(tag)).length > 0);
            const articleTags = filteredArticles.map(article => article.tags).join().split(',');

            if (id == 0) {
              //if it is the first filter, need to make all tags enabled if they have mapped articles.
              const curCatTags = catTags[curFilterCat];
              curCatTags.forEach(tag => {
                tag.enabled = tag.label in tagArticleMap;
              });
            }

            $.each(catTags, (cat, tags) => {
              if (cat != curFilterCat && !(cat in filteredCat)) {
                tags.forEach(tag => {
                  tag.enabled = articleTags.includes(tag.label) || allCheckedTags[cat].includes(tag.label);
                });
              }
            });
          }

          filteredCat[curFilterCat] = true;
        });
      }

      this.filterStates.filteredArticleList = filteredArticles;
    }
  }

  updateTagArticleMap(articles) {
    const tagArticleMap = this.tagStates.tagArticleMap;

    articles.forEach((article, id) => {
      article.tags.forEach(tag => {
        tagArticleMap[tag] = tagArticleMap[tag] || [];
        tagArticleMap[tag].push(id);
      });
    });
  }

  clearHashTag() {
    history.pushState({}, '', '#');
  }

  parseLocationHash() {
    const checkedTags = this.tagStates.checked;
    const hashInUrl = location.hash.substr(1); //remove prefix '#'
    if(hashInUrl.length == 0){
      return false;
    }

    const hashTagMaps = hashInUrl.split('&'); //split hash with '&'
    if (hashTagMaps.length > 0) {
      for (const catLabel in checkedTags) {
        checkedTags[catLabel] = [];
      }

      hashTagMaps.forEach(tagMap => {
        const hashTagKV = tagMap.split('=');
        if (hashTagKV.length == 2) {
          const hashTagKey = hashTagKV[0];
          const hashTagValues = hashTagKV[1].split(',');
          checkedTags[hashTagKey] = hashTagValues;
          this.updateFilter(hashTagKey, true);
        }
      });

      this.tagStates.checked = checkedTags;
    }
  }

  ensureFilterVisible() {
    const filterOffsetTop = this.$root.offset().top;
    const windowScrollTop = $(window).scrollTop();
    if (windowScrollTop > filterOffsetTop) {
      $('html,body').animate({ scrollTop: filterOffsetTop }, 'slow');
    }
  }

  trackEvents() {
    this.$resetButton.on('click', () => {
      this.updateFilter(true);
      this.clearHashTag();
      this.fireOnTagStateUpdated();
      return false;
    });

    $(window).on('hashchange', () => {
      this.parseLocationHash();
      this.fireOnTagStateUpdated();
      this.ensureFilterVisible();
      return false;
    });

    this.$tagContainer.on('click', '.chip', (event) => {
      const $chip = $(event.target);
      const tagSlug = $chip.attr('data-chip-slug');
      const catLabel = $chip.closest('.tag-row').attr('data-tag-cat-label');
      const checkedTags = this.tagStates.checked[catLabel];
      let isChecked = false;

      if ($chip.is('[disabled]')) {
        return false;
      }

      const id = checkedTags.indexOf(tagSlug);
      if (id != -1) {
        checkedTags.splice(id, 1);
      } else {
        isChecked = true;
        checkedTags.push(tagSlug);
      }

      this.updateFilter(catLabel, isChecked);
      this.updateLocationHash();
      this.fireOnTagStateUpdated();
      return false;
    });
  }

  setArticleManager(articleManager) {
    this.articleManager = articleManager;
  }
}

export class ISSArticleList {
  constructor(rootEl, tagManager) {
    this.$root = $('.article-list', rootEl);
    this.$articleBottomLine = this.$root.find('.article-bottom-line');
    this.articleStates = {
      articleNumber: ISSArticleConstants.TEASER_VIEW_ARTICLE_NUMBER,
    };

    this.tagManager = tagManager;
    tagManager.setArticleManager(this);
    this.trackEvents();
  }

  rebuild() {
    this.buildResults();
  }

  monitorArticleScrolling(entries) {
    const entry = entries[0];
    if (entry.isIntersecting) {
      this.renderArticles();
    }
  }

  trackEvents() {
    let observer = new IntersectionObserver(this.monitorArticleScrolling.bind(this), {});
    observer.observe(this.$articleBottomLine[0]);
  }

  buildResults() {
    this.loadWholeList().then(articles => {
      this.articles = articles;
      this.$articleRoot = this.$root.find('.articles');
      this.$articleRoot.html(this.buildArticleCells(articles));
      this.$articleCells = this.$articleRoot.find('.cell');
      this.tagManager.loadArticles(articles);
    });
  }

  buildArticleCells(articles) {
    return articles.map(article => {
      return `<div class="cell hide" data-article-id="${article.id}"></div>`;
    }).join('');
  }

  buildArticleNode(article) {
    const subcategoryTagMap = this.tagManager.getSubcategoryTagMap();
    const categoryName = article.category_apphook[0];
    const categoryUrl = subcategoryTagMap[categoryName] || categoryName;
    const subcategory_tag_label = ISSArticleConstants.SUBCATEGORY_TAG_LABEL;

    return `
      <fil-editorial-card url="${article.url}"
          image="${article.teaser_image}"
          headline="${article.title}"
          category="${categoryName}"
          categoryurl="#${subcategory_tag_label}=${categoryUrl}"
          subtext="${article.sub_title}"
          author='[{"pureText":true, "other":{"description": "${article.date}"}}]'
      ></fil-editorial-card>`;
  }

  loadWholeList() {
    return $.ajax({
      url: ISSArticleConstants.ARTICLE_API_URL,
      type: 'GET'
    }).then(data => data.articles);
  }

  setVisible(filteredArticles) {
    if (filteredArticles) {
      this.visibleArticles = filteredArticles;
      this.renderArticles(true);
    }
  }

  renderArticles(firstDisplay) {
    const articleStates = this.articleStates;
    let displayArticles;

    if (!this.articles) {
      return false;
    }

    if (firstDisplay) {
      displayArticles = this.visibleArticles.slice(0, ISSArticleConstants.TEASER_VIEW_ARTICLE_NUMBER);
      articleStates.articleNumber = ISSArticleConstants.TEASER_VIEW_ARTICLE_NUMBER;
      this.$articleCells.addClass('hide');
    } else {
      if (this.visibleArticles && articleStates.hasMore) {
        displayArticles = this.visibleArticles.slice(
          articleStates.articleNumber,
          articleStates.articleNumber + ISSArticleConstants.ADDED_ARTICLE_NUMBER
        );
        articleStates.articleNumber += ISSArticleConstants.ADDED_ARTICLE_NUMBER;
      }
    }

    if (displayArticles) {
      displayArticles.forEach(article => {
        const $cell = this.$articleCells.filter(`[data-article-id="${article.id}"]`);
        if ($cell.length) {
          if (!$cell.hasClass('rendered')) {
            $cell.html(this.buildArticleNode(article)).addClass('rendered');
          }
          $cell.removeClass('hide');
        }
      });

      articleStates.hasMore = articleStates.articleNumber < this.visibleArticles.length;
    }
  }
}
