// eslint-disable-next-line
import 'choices.js/public/assets/scripts/choices.min.js';

/* eslint-disable */
const USER_DEFAULTS = {
  isMultiple: false,
  isDisable: false,
  isIcon: false,
  placeholder: false,
  selectedText: 'Options selected',
};

const selectClassNames = {
  containerOuter: 'fil-choices',
  containerInner: 'fil-choices__inner',
  input: 'fil-choices__input',
  inputCloned: 'fil-choices__input--cloned',
  list: 'fil-choices__list',
  listItems: 'fil-choices__list--multiple',
  listSingle: 'fil-choices__list--single',
  listDropdown: 'fil-choices__list--dropdown',
  item: 'fil-choices__item',
  itemSelectable: 'fil-choices__item--selectable',
  itemDisabled: 'fil-choices__item--disabled',
  itemChoice: 'fil-choices__item--choice',
  placeholder: 'fil-choices__placeholder',
  group: 'fil-choices__group',
  groupHeading: 'fil-choices__heading',
  button: 'fil-choices__button',
};

export const selectMask = (el, options, isMultiple) => {
  if (!isMultiple) {
    return new Choices(el, {
      placeholder: false,
      itemSelectText: '',
      shouldSort: false,
      renderSelectedChoices: 'always',
      searchEnabled: false,
      removeItems: true,
      classNames: selectClassNames,
      callbackOnCreateTemplates: function (template) {
        return {
          choice: (classNames, data) => {
            return template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${
              data.selected ? classNames.selectedState : ''
            } ${
              data.disabled ? classNames.itemDisabled : classNames.itemSelectable
            }" data-select-text="${this.config.itemSelectText}" data-choice ${
              data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'
            } data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            } aria-selected="${data.selected ? 'true' : 'false'}">
            ${data.label}
          </div>
        `);
          },
        };
      },
      ...options,
    });
  }

  return new Choices(el, {
    placeholder: false,
    itemSelectText: '',
    shouldSort: false,
    renderSelectedChoices: 'always',
    searchEnabled: false,
    removeItems: true,
    removeItemButton: true,
    renderChoiceLimit: 0,
    resetScrollPosition: false,
    classNames: selectClassNames,
    callbackOnCreateTemplates: function (template) {
      return {
        item: (classNames, data) => {
          return template(`
            <div style="display: none;" class="${classNames.item} ${
            data.highlighted ? classNames.highlightedState : classNames.itemSelectable
          } ${data.placeholder ? classNames.placeholder : ''}" data-item data-id="${
            data.id
          }" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${
            data.disabled ? 'aria-disabled="true"' : ''
          }>
          ${data.value}
            </div>
          `);
        },
        choice: (classNames, data) => {
          return template(`
          <div id="${`${classNames.item}--multiple-${data.id}`}" class="${classNames.item} ${
            classNames.itemChoice
          } ${data.selected ? classNames.selectedState : ''} ${
            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
          }" data-select-text="${this.config.itemSelectText}" data-choice ${
            data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'
          } data-id="${data.id}" data-value="${data.value}" ${
            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
          } aria-selected="${data.selected ? 'true' : 'false'}">
            <div class="fil-checkbox">
                        <input
                          type="checkbox"
                          class="fil-checkbox__field"
                          value="${data.value}"
                          ${data.selected && 'checked'}
                        />
                        <span class="fil-checkbox__label" for=""
                          >${data.label}</span
                        >
                      </div>
          </div>
        `);
        },
      };
    },
    ...options,
  });
};

class Select {
  constructor(element = '[data-select]', userConfig = {}) {
    this.config = Object.assign({}, USER_DEFAULTS, userConfig);

    this.passedElement = typeof element === 'string' ? document.querySelector(element) : element;

    if (!this.passedElement) {
      throw new Error('Can not get element!');
    }

    if (!this.passedElement instanceof HTMLUListElement) {
      throw new TypeError('Expected type is ul');
    }

    this.initialized = false;
    this.init();
  }

  init() {
    if (this.initialized) {
      return;
    }
    const { isMultiple, isDisable, ...config } = this.config;
    this.ChoiceInstance = selectMask(this.passedElement, config, isMultiple);
    this.containerOuter = this.ChoiceInstance.containerOuter.element;
    this.containerInner = this.ChoiceInstance.containerInner.element;

    if (isDisable) {
      this.ChoiceInstance.disable();
    }

    if (isMultiple) {
      this._handleMultipleChoice();
    }

    this.initialized = true;
  }

  // add or remove placeholder node
  _handlePlaceholder(operation) {
    const inner = this.containerInner;
    if (!operation) {
      const placeholderSpan = document.createElement('span');
      placeholderSpan.setAttribute('id', 'placeholder');
      placeholderSpan.textContent = this.config.placeholder;
      inner.appendChild(placeholderSpan);
    } else {
      const placeholderNode = inner.querySelector('#placeholder');
      if (placeholderNode) {
        placeholderNode.remove();
      }
    }
  }

  _handleRemovableEle(counts) {
    const multipleDemo = this.containerInner;
    const input = this.containerInner.querySelector('input');
    // check whether have removable node
    const removableNode = multipleDemo.querySelector('#removedItem');

    if (counts === 0) {
      if (removableNode) removableNode.remove();
    } else {
      if (!removableNode) {
        const { selectedText } = this.config;
        const actionWrapper = document.createElement('div');
        actionWrapper.setAttribute('id', 'removedItem');
        actionWrapper.setAttribute('class', 'fil-choices__list fil-choices__list--multiple');
        actionWrapper.innerHTML = `<div class="fil-choices__item fil-choices__item--removable"><span>${counts}</span> &nbsp;<button>x</button></div><span>${selectedText}</span>`;
        const btn = actionWrapper.querySelector('button');

        btn.addEventListener('click', e => {
          this.ChoiceInstance.removeActiveItems();
          e.stopPropagation();
          this._handlePlaceholder();
          actionWrapper.remove();
        });
        multipleDemo.insertBefore(actionWrapper, input);
      } else {
        const selectedCountNode = removableNode.querySelector('span');
        selectedCountNode.textContent = counts;
      }
    }
  }

  _handleMultipleChoice() {
    this._handlePlaceholder();
    this.containerOuter.addEventListener('choice', event => {
      const currentChoiceValue = event.detail.choice.value;
      if (event.detail.choice.selected) {
        setTimeout(() => {
          this.ChoiceInstance.removeActiveItemsByValue(currentChoiceValue);
          this.ChoiceInstance._triggerChange(currentChoiceValue);
        }, 10);
      }
    });

    this.containerOuter.addEventListener('change', () => {
      const values = this.ChoiceInstance.getValue(true);
      if (values.length > 0) {
        this._handleRemovableEle(values.length);
        this._handlePlaceholder('remove');
      } else {
        this._handleRemovableEle(0);
        this._handlePlaceholder();
      }
      this.value = values;
    });
  }
}

export default Select;
