$(() => {
  $('.fil-cards .mini-promo').on('click', () => {
    window.location = $(this)
      .find('.cta-section a')
      .attr('href');
    return false;
  });

  /*
  * standard card 3.0.4 doesn't pass ctaExtraProps attribute to card headline link
  */
  $('.fil-standard-card-wrapper fil-standard-card').each((_, card) => {
    const ctaurl = card.getAttribute('ctaurl');
    let cardExtraProps = card.getAttribute('ctaextraprops');
    if (ctaurl && cardExtraProps) {
      const target = JSON.parse(cardExtraProps).target;
      if(target){
        card.shadowRoot.querySelector('.fil-card__headline').target = target;
      }
    }
  });
});
