const PDF_DJANGO_API_URL = '/iss-article/pdf/';

export class ISSArticlePDFExporter {
  constructor(el) {
    this.$triggerDOM = $(el);
    this.started = false;
  }

  onStart() {
    this.$triggerDOM.hide();
    this.$loader = $('<fil-loader kind="ring" size="22"></fil-loader>').insertAfter(this.$triggerDOM);
    this.articleURL = $('meta[property="og:url"]').attr('content') || location.href;
    this.articleName = $('h1.article-heading').text();
    this.articleDomain = this.getPageDomain();
    this.pageContent = this._getPageHTML();
  }

  _get_csrf_token() {
    return $('[name=csrfmiddlewaretoken]').val();
  }

  _downloadPdfData(pdfData) {
    const pdfFileName = `${this.articleName.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')}.pdf`;
    const linkSource = `data:application/pdf;base64,${pdfData}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = pdfFileName;
    downloadLink.click();
  }

  _getPageHTML() {
    let pageContent = `<html>${$('html').html()}</html>`;

    //TODO:
    //1. leave external <style> tags alone now, check whether the lambda API works with external resource.
    //2. strip off the <scripts> tags. as ensighten bootstrap.js script will run for a long time so that API gateway will expire.
    //   strip off notofont css link
    pageContent = pageContent.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      ''
    );
    pageContent = pageContent.replace(
      /<link\s+.*?href="[^"]+Noto\+Sans[^>]+>/gi,
      ''
    );
    //3. replace everviz charts in <iframe> tag with their thumbnail images.
    pageContent = pageContent.replace(
      /<iframe\s+.*?\s+src="https:\/\/app\.everviz\.com\/embed\/(.*?)\/?".*?<\/iframe>/gi,
      `<p><img src="https://app.everviz.com/thumbnails/$1.png?${Date.now()}"/></p>`
    );
    //4. inject additional page style to header section.
    //   add additional script to tail of body section.
    pageContent = pageContent.replace(/<\/head>/i,
      `<style type="text/css">${this._getInlinePageStyles()}</style></head>`
    );
    pageContent = pageContent.replace(/<\/body>/i,
      `<script type="text/javascript">${this._getInlineScripts()}</script></body>`
    );
    //5. replace `url(../ pattern with path with '/static_cms/fil_site_assets_gds3', which will stopped by WAF.
    pageContent = pageContent.replace(/\.\.\//gi, '/static_cms/fil_site_assets_gds3/');
    //6. replace relative assets with absolute.
    pageContent = pageContent.replace(
      /\/static_cms\//gi,
      `${this.articleDomain}/static_cms/`
    );
    //7. replace /media/ with absolute path.
    pageContent = pageContent.replace(
      /\/media\//gi,
      `${this.articleDomain}/media/`
    );
    //8. replace all relative URLs with absolute URLs
    pageContent = pageContent.replace(/href="\//g, `href="${this.articleDomain}/`);
    //9. replace footer color with important color styles
    pageContent = pageContent.replace(/<font\s+color="([^"]+)"/ig, '<font style=\'color:$1 !important\'');
    //10. replace s3files image with absolute URLs
    pageContent = pageContent.replace(/\/s3files\//gi, `${this.articleDomain}/s3files/`);

    return pageContent;
  }

  _getInlineScripts() {
    //when mediaquery switch to print mode, we do following actions.
    //1. dynamically update header image height to make it the same as title container.
    //2. when content navigation list is empty, hide it completely.
    //3. make table unresponsive (remove `fil-table--responsive` class).
    return 'window.matchMedia("print").addListener(function (media) { \
      if(media.matches){ \
        document.querySelector(".article-bg-image-wrapper").style.height = document.querySelector(".article-detail-component-wrapper").offsetHeight + "px"; \
        var contentNavigationContainer = document.querySelector(".content-navigation.is-mobile"); \
        if(!contentNavigationContainer.querySelector("ul > li")) { \
          contentNavigationContainer.classList.add("hide"); \
        } \
        var tables = document.querySelectorAll(".fil-table--responsive"); \
        var table_len = tables.length; \
        for (var i=0; i<table_len; ++i) { \
          tables[i].classList.remove("fil-table--responsive"); \
        } \
      } \
    });';
  }

  _getInlinePageStyles() {
    return '@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,700&subset=chinese-traditional&display=swap);body {font-family:"Neuzeit Grotesk","NeuzeitGro","Noto Sans TC","Microsoft JhengHei","微軟正黑體","STHeiti",sans-serif !important;}';
  }

  _get_pdf_options() {
    return {};
  }

  getPageDomain() {
    let articleDomain = this.articleURL.match(/^https?:\/\/[^/]+/);
    if (articleDomain) {
      articleDomain = articleDomain[0];
    }

    const pageDomain = `${location.protocol}//${location.hostname}`;
    return articleDomain || pageDomain;
  }

  onFinished() {
    this.$loader.remove();
    this.$triggerDOM.show();
  }

  fetch() {
    const csrftoken = this._get_csrf_token();
    const pdfOptions = this._get_pdf_options();
    const articlePath = window.iss_article_name || location.pathname;

    return $.ajax({
      type: 'POST',
      url: PDF_DJANGO_API_URL,
      headers: {
        'X-CSRFToken': csrftoken,
        'Accept': 'application/pdf'
      },
      data: {
        filename: this.articleName,
        htmlString: this.pageContent,
        flag: true,
        options: pdfOptions,
        articlePath: articlePath
      }
    }).done((data, status) => {
      console.log(data, status);
      this._downloadPdfData(data);
    });
  }

  start() {
    if (!this.started) {
      this.onStart();
      this.fetch()
        .always(() => {
          this.onFinished();
          this.started = false;
        });

      this.started = true;
    }
  }
}
