require('./article-image-sticky');
require('./article-section-list');
require('./article-floating-sections');
require('./article-social-sharing');
import {ISSArticleTagFilters, ISSArticleList} from './article-filter-list';

$(function(){
  $('.fil-iss-filtered-article-list').each((i, el) => {
    const tagManager = new ISSArticleTagFilters(el);
    const articleList = new ISSArticleList(el, tagManager);
    articleList.rebuild();
  });
});
