import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { SearchEngine } from './searcher_class';
import { SpinnerHelper, SearchParamParser } from './common_helpers';
import { SearchPaginator } from './search_paginator';
import { DEFAULT_SEARCH_URL, DEFAULT_MIN_KEYWORD_LENGTH, DEFAULT_PAGE_FROM, DEFAULT_PER_PAGE} from './constants';
const queryString = require('qs');

export class BaseSearchFormHelper {
  constructor(globalStates) {
    this.states = globalStates;
    this.initSearchForm();
    this.bindEvents();
  }

  canSearch() {
    const value = this.getSearchValue();
    if (!this.minKeywordLen) {
      const { configHandlerInstance } = this.states;
      this.minKeywordLen = parseInt(configHandlerInstance.getForKey('SEARCH_SUGGESTER_MIN_CHARACTER')) || DEFAULT_MIN_KEYWORD_LENGTH;
    }
    return value.length >= this.minKeywordLen;
  }

  getSearchValue() {
    let value = '';
    if (this.$form.length) {
      value = this.$searchInput.val();
    }

    return $.trim(value);
  }

  initSearchForm() {
    const { searchParams, typeahead } = this.states;
    const $form = this.$form = this.getForm();
    this.$searchButton = this.$form.find('.search-button');
    this.$searchInput = this.$form.find('[name="query_text"]');
    if ($form.length) {
      $form.find('[name="pagecategory"]').val(searchParams['pagecategory']);
      this.$searchInput.val(searchParams['query_text']);
      typeahead.initSuggester(this, searchParams['pagecategory']);
      this.updateSubmitButtonState();
    }
  }

  getSearchInput() {
    return this.$searchInput;
  }

  getTypeAheadDOM(){
    return this.$form.find('.typeahead');
  }

  getForm() {
    return null;
  }

  bindEvents() {
    this.$searchButton.on('click', event => {
      event.preventDefault();
      this.preSearch();
    });
  }

  preSearch() {
    if (!this.$searchButton.is('[disabled]')) {
      this.search();
    }
  }

  search() {
    throw new ReferenceError('Must implement search() method');
  }

  updateSubmitButtonState() {
    this.$searchButton.attr('disabled', !this.canSearch());
  }
}

export class TopSearchFormHelper extends BaseSearchFormHelper {
  getForm() {
    return $('form.topsearch');
  }

  search() {
    this.$form.trigger('submit');
  }

  hideNav() {
    if (!MediaQuery.atLeast('large')) {
      this.hideNavComplete();
    } else {
      $('.tier-one-wrapper')
        .fadeOut(100, 'linear')
        .promise()
        .done(this.hideNavComplete.bind(this));
    }
  }

  hideNavComplete() {
    this.$form.find('.search-wrapper').toggleClass('active');
  }

  revealNav() {
    const $searchWrapper = this.$form.find('.search-wrapper');
    $searchWrapper.removeClass('active');
    if (MediaQuery.atLeast('large')) {
      setTimeout(function () {
        $('.tier-one-wrapper').fadeIn(400, 'linear');
      }, 300);
    }
  }

  bindEvents() {
    const $searchWrapper = this.$form.find('.search-wrapper');
    super.bindEvents();

    this.$form.find('.backdrop').on('click', e => {
      this.$searchInput.val('');
      this.updateSubmitButtonState();
      this.revealNav();
    });

    this.$searchButton.on('click', e => {
      if (!$searchWrapper.hasClass('active')) {
        this.hideNav();
      }

      e.preventDefault();
    });
  }
}

export class SearchResultPageFormHelper extends BaseSearchFormHelper {
  getForm() {
    return $('form.resultsPage');
  }

  constructor(globalStates) {
    super(globalStates);
    if(this.$form.length > 0) {
      this.initSearchTabs();
      this.initSearchParamParser();
    }
  }

  initSearchParamParser() {
    this.searchParamParser = new SearchParamParser();
    this.searchParamParser.onHashChanged(states => {
      this.handleSearchParamChanged(states);
    });

    const states = this.searchParamParser.updateStates();
    this.handleSearchParamChanged(states, true);
  }

  handleSearchParamChanged(states, initial) {
    const { typeahead } = this.states;
    const {'tab-index':tabIndex = 0, page = DEFAULT_PAGE_FROM, perPage = DEFAULT_PER_PAGE, 'query_text':searchTerm = '' } = states;
    const $tabDOM = this.$tabContainer.find('fil-tab').eq(tabIndex);
    const doctype = $tabDOM.data('identity');
    const tabPaginator = this.searchTabs[doctype];

    if(!initial) {
      this.switchToTab(tabIndex);
      tabPaginator.setState(page, perPage, true);
      tabPaginator.updateWebComponentStates(page, perPage);
    }

    if(searchTerm && (searchTerm != this.getSearchValue() || initial)){
      typeahead.setQuery(searchTerm);
      this.updateSubmitButtonState();
      this.search(searchTerm, true).then((newSearch)=>{
        if(newSearch) {
          this.switchToTab(tabIndex);
          tabPaginator.updateWebComponentStates(page, perPage);
        }
      }); //keep location unchanged.
    }
  }

  initSearchTabs() {
    const { searchParams, fundsEnabled } = this.states;
    const docTypeLabels = this.docTypeLabels = {};
    this.$tabContainer = $('.search-results__tabs-container');
    this.$searchPaginators = $('[data-paginator="search"]');
    this.searchTabs = {};
    const searchURL = this.$form.attr('action') || DEFAULT_SEARCH_URL;
    this.searcher = new SearchEngine(this, {
      url: searchURL,
      pageCategory: searchParams['pagecategory'],
      fundsEnabled: fundsEnabled,
    });
    this.spinner = new SpinnerHelper();

    this.$searchPaginators.each((index, rootContainer) => {
      const $root = $(rootContainer);
      const $tabDOM = $root.closest('fil-tab');
      const doctype = $tabDOM.data('identity');
      docTypeLabels[doctype] = $tabDOM.attr('label');
      this.searchTabs[doctype] = new SearchPaginator($root, {
        searcher: this.searcher,
        form: this,
        tabIndex: index,
        disableSEO: true,
        disableURL: true
      });
    });

    this.$tabContainer.on('onclick', event => {
      const tagName = event.target.tagName.toLowerCase();
      if(tagName == 'fil-tabs') {
        const tabIndex = event.detail.value;
        if(this.searchParamParser.isChanged('tab-index', tabIndex)) {
          this.searchParamParser.updateP({'tab-index': tabIndex, page: DEFAULT_PAGE_FROM, perPage: DEFAULT_PER_PAGE});
        }
      }
    });
  }

  updatePagination(page, perPage) {
    if(page > 0) {
      this.searchParamParser.updateP({page, perPage});
    }
  }

  getDocTypeLabel(type) {
    return this.docTypeLabels[type];
  }

  switchToTab(tabIndex) {
    const tabs = this.$tabContainer.find('fil-tabs')[0];
    if (tabs && tabs.shadowRoot) {
      const $tabsRoot = $(tabs.shadowRoot);
      $tabsRoot.find('.tabs > .tabs-title').eq(tabIndex).trigger('click');
    }
  }

  search(value, keepLocation) {
    const searchTerm = value || this.getSearchValue();
    const dfd = $.Deferred();

    if (searchTerm) {
      this.searcher.search(searchTerm).then((newSearch, isResultEmpty) => {
        this.spinner.off();
        if (newSearch) {
          if (isResultEmpty) {
            $('.search-results__empty-message').removeClass('hide');
            this.$tabContainer.addClass('invisible');
          } else {
            $('.search-results__empty-message').addClass('hide');
            this.$tabContainer.removeClass('invisible');

            if(!keepLocation) {
              this.switchToTab(0);
            }
          }

          $.each(this.searchTabs, (docType, searchPaginator) => {
            searchPaginator.rebuild(docType, true);
          });

          dfd.resolve(newSearch, isResultEmpty);
        }
      }, (err) => {
        console.error(err);
        dfd.reject(err);
      });

      this.spinner.on();
      this.updateLocationQueryParams(searchTerm, keepLocation);
    }

    return dfd.promise();
  }

  updateLocationQueryParams(searchTerm, keepLocation) {
    if(!keepLocation){
      this.searchParamParser.reset();
    }
    this.searchParamParser.updateP('query_text', searchTerm);
  }
}
