import { GetDimensions } from '../util/util';

/**
 * @target: Element
 * @messageContainer: Element
 * @positionInfo: {position:'', alignment: '', messageHeight: '', messageWidth: ''}
 */
export const calculatePosition = (target, messageContainer, positionInfo) => {
  const TT_WIDTH = positionInfo.messageWidth;

  // since icon height is not same with icon line height, so set diff value for calculation
  const TT_OFFSET_TOP = 6;
  const TT_OFFSET_BOTTOM = 11;
  const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = target;

  const tipMessageHeight = positionInfo.messageHeight;

  const alignment = {};
  alignment.left = offsetLeft;
  alignment.right = offsetLeft + offsetWidth - TT_WIDTH;
  alignment.auto = offsetLeft + offsetWidth / 2 - TT_WIDTH / 2;

  // check whether container has enough space for tooltip message
  const anchorDimensions = GetDimensions(target);
  const { width: windowWidth, height: windowHeight } = anchorDimensions.windowDims;
  const topSpace = anchorDimensions.offset.top;
  const bottomSpace = windowHeight - topSpace - anchorDimensions.height;

  let top;
  // let left;
  if (positionInfo.position === 'bottom') {
    if (
      bottomSpace >= tipMessageHeight ||
      (topSpace < tipMessageHeight && bottomSpace < tipMessageHeight)
    ) {
      top = offsetTop + offsetHeight + TT_OFFSET_TOP;
    } else {
      top = offsetTop - TT_OFFSET_BOTTOM - tipMessageHeight;
    }
  } else if (topSpace >= tipMessageHeight) {
    top = offsetTop - TT_OFFSET_BOTTOM - tipMessageHeight;
  } else {
    top = offsetTop + offsetHeight + TT_OFFSET_TOP;
  }

  const rightSpace = windowWidth - anchorDimensions.width - anchorDimensions.offset.left;
  const leftSpace = anchorDimensions.offset.left;

  const windowCenterCondition =
    rightSpace < TT_WIDTH &&
    leftSpace < TT_WIDTH &&
    (rightSpace < TT_WIDTH / 2 || leftSpace < TT_WIDTH / 2);
  const windowCenterLeft = (windowWidth - TT_WIDTH) / 2 - anchorDimensions.offset.left;

  let horizontalSpace = '';
  if (positionInfo.alignment === 'left') {
    if (rightSpace >= TT_WIDTH) {
      horizontalSpace = alignment.left;
    } else if (leftSpace >= TT_WIDTH) {
      horizontalSpace = alignment.right;
    } else if (windowCenterCondition) {
      // center of window
      horizontalSpace = windowCenterLeft;
    } else {
      horizontalSpace = alignment.auto;
    }
  } else if (positionInfo.alignment === 'right') {
    if (leftSpace >= TT_WIDTH) {
      horizontalSpace = alignment.right;
    } else if (rightSpace >= TT_WIDTH) {
      horizontalSpace = alignment.left;
    } else if (windowCenterCondition) {
      horizontalSpace = windowCenterLeft;
    } else {
      horizontalSpace = alignment.auto;
    }
  } else if (positionInfo.alignment === 'center') {
    if (rightSpace >= TT_WIDTH / 2 && leftSpace >= TT_WIDTH / 2) {
      horizontalSpace = alignment.auto;
    } else if (rightSpace < TT_WIDTH / 2 && leftSpace >= TT_WIDTH) {
      horizontalSpace = alignment.right;
    } else if (leftSpace < TT_WIDTH / 2 && rightSpace >= TT_WIDTH) {
      horizontalSpace = alignment.left;
    } else if (windowCenterCondition) {
      horizontalSpace = windowCenterLeft;
    } else {
      horizontalSpace = alignment.auto;
    }
  }
  return `top: ${top}px; left: ${horizontalSpace}px; display: block; position: absolute; width: ${positionInfo.messageWidth}px;`;
};

/* eslint-disable */
const USER_DEFAULTS = { position: 'bottom', alignment: 'left' };

/**
 * Construct a new Tooltip instance by passing the configuration object
 *
 * @param {String | HTMLElement} element
 * @param {Object} opts
 */

class Tooltip {
  /**
   * @param {string | HTMLUlElement } element
   */
  constructor(element = '[data-tooltip]', userConfig = {}) {
    this.config = Object.assign({}, USER_DEFAULTS, userConfig);

    this.passedElement = typeof element === 'string' ? document.querySelector(element) : element;

    if (!this.passedElement) {
      throw new Error('Can not get element!');
    }

    if (!this.passedElement instanceof HTMLUListElement) {
      throw new TypeError('Expected type is ul');
    }

    // this.svgElement = document.createElement("div").innerHTML = svg.default;
    this.initialised = false;
    this.init();
  }

  init() {
    if (this.initialised) {
      return;
    }

    this.template = this.passedElement.querySelector('.fil-tooltip-msg');
    this.tip = this.passedElement.firstElementChild;
    this.messageContainerOffset = {
      height: this.template.offsetHeight,
      width: this.template.offsetWidth,
    };
    this.template.style.display = 'none';
    this.svg = this.tip.querySelector('svg');
    this.isActive = false;
    this._appendEvent();
    this.initialised = true;
  }

  _appendEvent() {
    this.tip.addEventListener('click', event => {
      this._toggle();
    });

    document.addEventListener('click', event => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(this.tip)) {
        this._hide();
      }
    });

    document.addEventListener('keydown', event => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this._hide();
      }
    });

    this.svg &&
      this.svg.addEventListener('mouseover', event => {
        this.svg.setAttribute('data-filled', 'true');
      });

    this.svg &&
      this.svg.addEventListener('mouseout', event => {
        this.svg.removeAttribute('data-filled');
      });
  }

  _show() {
    const { position, alignment } = this.config;
    this.template.style.cssText = calculatePosition(this.tip, this.template, {
      position,
      alignment,
      messageHeight: this.messageContainerOffset.height,
      messageWidth: this.messageContainerOffset.width,
    });
    this.isActive = true;
    setTimeout(() => (this.template.style.display = 'block'), 200);
  }

  _hide() {
    setTimeout(() => {
      this.template.style.display = 'none';
    }, 200);

    this.isActive = false;
  }

  /**
   * adds a toggle method, in addition to the static show() & hide() functions
   * @function
   */
  _toggle() {
    if (this.isActive) {
      this._hide();
    } else {
      this._show();
    }
  }
}

export default Tooltip;
