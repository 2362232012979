const Paginator = require('_fil/fil-global-frontend-gds3/paginator');
const AUTOSCROLL_SPEED = 1000;

class ArticlePaginator extends Paginator {
  constructor(rootEl, opts = {}) {
    super(rootEl, opts);
    // Immediately select and cache the articles
    this.$articles = this.$results.find('.fil-article-list__card');
    this.updateStateFromUrl();
  }

  /**
   * Iterate through the result DOM elements and conditionally display the
   * elements based on the provided index bounds.
   *
   * @override
   * @param {[number, number]} bounds Minimum and maximum index for display
   */
  buildResults(bounds) {
    const [min, max] = bounds;
    this.$articles.each((i, el) => {
      const visible = min <= i && i <= max;
      $(el).toggleClass('hide', !visible);
    });
  }

  /**
   * Retrieve the lower and upper bounds of result indices required for this
   * page
   *
   * @override
   * @param  {number} page Page number to retrieve values for
   * @return {[number, number]} Minimum and maximum result index to display
   */
  getResultsForPage(page) {
    return [page * this.perPage, (page + 1) * this.perPage - 1];
  }

  /**
   * Count the number of result elements
   *
   * @override
   * @return {number} Total results
   */
  getTotalResults() {
    return this.$articles.length;
  }

  /**
   * Scroll smoothly to the top of the article list
   */
  onPageChange() {
    const articlePos = Math.round(this.$root.offset().top);
    $('html, body').animate({ scrollTop: articlePos }, AUTOSCROLL_SPEED);
  }

  /**
   * Article query API provides the total article count. This function
   * handles caching the value and conditionally rebuilding the navigation
   * when the total result changes (e.g. when performing a first-time load)
   *
   * @override
   * @param {number} resultCount Total number of results from the query
   */
  setResultCount(resultCount) {
    if (this.resultCount !== resultCount) {
      this.resultCount = resultCount;
      this.rebuildNavigation();
    }
  }
}

module.exports = ArticlePaginator;
