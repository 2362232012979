// Breakpoints Constant
export const SMALL = 'SMALL';
export const MEDIUM = 'MEDIUM';
export const LARGE = 'LARGE';
export const XLARGE = 'XLARGE';

/**
 * Get viewport width of current device
 */
export const getDeviceType = () => {
  const breakpoints = {
    medium: 680,
    large: 1000,
    xlarge: 1296,
  };

  const isClient = typeof window !== 'undefined';
  const viewPortWidth = isClient ? window.innerWidth : -1;

  if (viewPortWidth === -1) return SMALL;

  if (viewPortWidth < breakpoints.medium) {
    return SMALL;
  }

  if (viewPortWidth < breakpoints.large && viewPortWidth >= breakpoints.medium) {
    return MEDIUM;
  }

  if (viewPortWidth < breakpoints.xlarge && viewPortWidth >= breakpoints.large) {
    return LARGE;
  }

  return XLARGE;
};

/**
 * @param {HTMLElement} element
 * @param {string} types One or more space-separated event types, such as 'click', 'click click.gds.tabs'
 * @param {object} data Any data passed when initializing the event.
 */
export const dispatchEvent = (element, types, data = null, eventProps = {}) => {
  const whitespace = /[^\x20\t\r\n\f]+/g;
  const eventTypes = (types || '').match(whitespace) || [''];
  const typeLength = eventTypes.length;
  const props = {
    bubbles: true,
    cancelable: true,
    composed: true,
    ...eventProps,
  };

  for (let i = typeLength - 1; i >= 0; i -= 1) {
    const type = eventTypes[i];
    const event = new CustomEvent(type, {
      detail: data,
      ...props,
    });
    element.dispatchEvent(event);
  }
};

/**
 * detect IEEdge
 * returns version of IE/Edge or false, if browser is not a Microsoft browser
 */
export const detectIEEdge = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};

/**
 * get element position
 */
export function GetDimensions(elements) {
  const elem = elements.length ? elements[0] : elements;

  if (elem === window || elem === document) {
    throw new Error("I'm sorry, I'm afraid I can't do that.");
  }

  const rect = elem.getBoundingClientRect();
  const winRect = document.body.getBoundingClientRect();
  const parRect = elem.parentNode.getBoundingClientRect();
  const winY = window.pageYOffset;
  const winX = window.pageXOffset;

  return {
    width: rect.width,
    height: rect.height,
    offset: {
      top: rect.top + winY,
      left: rect.left + winX,
    },
    parentDims: {
      width: parRect.width,
      height: parRect.height,
      offset: {
        top: parRect.top + winY,
        left: parRect.left + winX,
      },
    },
    windowDims: {
      width: winRect.width,
      height: winRect.height,
      offset: {
        top: winY,
        left: winX,
      },
    },
  };
}

/**
 * event target
 */
export function getEventTarget(event) {
  try {
    if (typeof event.composedPath === 'function') {
      const path = event.composedPath();
      return path[0];
    }
    return event.target;
  } catch (error) {
    return event.target;
  }
}

export function createElement(tag, className, content) {
  const e = window.document.createElement(tag);
  const eleClassName = className || '';
  const eleContent = content || '';
  e.className = eleClassName;
  if (eleContent !== undefined) e.textContent = eleContent;
  return e;
}

export const toArray = arrayLike => Array.prototype.slice.call(arrayLike);
