export const ROOT_SELECTOR = '.tw-fund-search-wrapper';
export const INPUT_SELECTOR = '.fund-search-input-box';
export const INPUT_WRAPPER_SELECTOR = '.fund-search-input-inner-wrapper';
export const INPUT_ICON_SELECTOR = '.fund-search-input-inner-wrapper fil-icon';
export const VIEW_ALL_BUTTON_SELECTOR = '.fund-search-button';
export const DROPDOWN_ITEM_CONTAINER_SELECTOR = '.fund-search-dropdown-item-container';
export const DROPDOWN_ITEM_CONTAINER_EMPTY_CLASS = 'is-empty';
export const DROPDOWN_ITEM_INNER_CONTAINER_SELECTOR = '.fund-search-dropdown-item-inner-container';
export const DROPDOWN_STICKY_ITEM_SELECTOR = '.fund-search-dropdown-sticky-item';
export const DROPDOWN_STICKY_ITEM_LINK_SELECTOR = '.fund-search-dropdown-sticky-item a';
export const DROPDOWN_ITEM_SELECTOR = '.fund-search-dropdown-item';

export const TYPE_DELAY = 500;
export const VISIBLE_DROPDOWN_NUM = 5;
export const KEYWORD_LENGTH_MIN = 1;
export const ICON_SEARCH = 'search';
export const OPEN_LINK_IN_NEW_TAB = false;
export const INPUT_SEARCH_FILTER_RULE = /[°`'~!¡@#§$%^&*()=?¿≠;:+‘"„±_.,<>{}\[\]\\\/]/gi;

export const DROPDOWN_ITEM_HTML = ' \
<div class="fund-search-dropdown-item"> \
  <a href="${fund_url}" target="${target}" isin="${isin}">${fund_name}</a> \
</div>';
export const FUND_NAME_LANGUAGETAG_TW = 'zh-TW';
export const FUND_NAME_LANGUAGETAG_EN = 'en-001';
export const FUND_ITEM_URL_PREFIX = 'fund-and-service/fundui/funds';
export const FUND_SEARCH_TOKENIZERS_API_URL = 'api/tw-fund/tokenizers/';

export const REGEX_GENERIC = /[` ~!@#$%^&*()\-_–+<>?:"{},./;'[\]]/g;
export const REGEX_FUND_NAME = /[` ~!@#$%^&*()_+<>?:"{},./;'[\]]/g;
export const FUND_NAME_SEP_CHAR = '-';

export const TAG_COMPONENT_KEY = 'FUND_SEARCH';
//ETK = Event Target Key
export const TAG_ETK_VIEW_ALL_FUNDS = 'VIEW_ALL_FUNDS';
export const TAG_ETK_INPUT_BOX = 'SEARCH_BOX_KEYWORD';
export const TAG_ETK_FUND_ITEM = 'FUND_ITEM';
export const TAG_ETK_STICKY_LINK = 'STICKY_LINK';
export const TAG_INPUT_DEBOUNCE = 2000;
