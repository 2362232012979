//gds3 components
import '@fil-global/gds-webcomponents/fil-button/fil-button.component';
import '@fil-global/gds-webcomponents/fil-button-group/fil-button-group.component';
import '@fil-global/gds-webcomponents/fil-external-link/fil-external-link.component';
import '@fil-global/gds-webcomponents/fil-accordion/fil-accordion.component';
import '@fil-global/gds-webcomponents/fil-accordion/fil-accordion-item.component';
import '@fil-global/gds-webcomponents/fil-standard-card/fil-standard-card.component';
import '@fil-global/gds-webcomponents/fil-editorial-card/fil-editorial-card.component';
import '@fil-global/gds-webcomponents/fil-text-media-grid/fil-text-media-grid.component';
import '@fil-global/gds-webcomponents/fil-pull-out/fil-pull-out.component';
import '@fil-global/gds-webcomponents/fil-page-level-message/fil-page-level-message.component';
import '@fil-global/gds-webcomponents/fil-pagination/fil-pagination.component';
import '@fil-global/gds-webcomponents/fil-tabs/fil-tabs.component';
import '@fil-global/gds-webcomponents/fil-tabs/fil-tab.component';
import '@fil-global/gds-webcomponents/fil-text-input/fil-text-input.component';
import '@fil-global/gds-webcomponents/fil-select/fil-select.component';
import '@fil-global/gds-webcomponents/fil-hero-home/fil-hero-block-item.component';
import '@fil-global/gds-webcomponents/fil-hero-home/fil-hero-block.component';
import '@fil-global/gds-webcomponents/fil-hero-home/fil-hero-card.component';
import '@fil-global/gds-webcomponents/fil-hero-home/fil-hero-home.component';
import '@fil-global/gds-webcomponents/fil-hero-home/fil-hero-intro.component';
import '@fil-global/gds-webcomponents/fil-switch/fil-switch.component';
import '@fil-global/gds-webcomponents/fil-loader/fil-loader.component';



//django components
/*
    Add required addon javascript files below
*/
require('_fil/fil-global-frontend-gds3/index');
require('_fil/global-cms-search/watchlist_integration');
require('_fil/global-cms-article/main');
require('_fil/global-cms-article-base/index');
// require('_fil/global-cms-article-editorial/main');
// require('_fil/global-cms-callback-form/callback-form');
// require('_fil/global-cms-carousel/carousel');
// require('_fil/global-cms-de-select-50/scripts');
// require('_fil/global-cms-email-form/index');
require('_fil/global-cms-way-finding-bar/global-nav');
require('_fil/global-cms-footer/footer');
require('_fil/global-cms-messaging/index');
// require('_fil/global-cms-isa-saving-tool/isa-saving-tool');
// require('_fil/global-cms-glossary/glossary');
// require('_fil/global-cms-login/authenticate_login');
// require('_fil/global-cms-modal/modal');
// require('_fil/global-cms-outlook-tables/table-tooltips');
// require('_fil/global-cms-outlook-share-links/share-links');
require('_fil/global-cms-hero-homepage/index');
require('_fil/global-cms-search/search_core');
require('_fil/global-cms-select/fil-select');
require('_fil/global-cms-showhide-wrapper/main');
// require('_fil/global-cms-showhide/show-hide');
require('_fil/global-cms-table/table-scroll');
require('_fil/global-cms-tabs/tabs');
// require('_fil/global-cms-video-gallery/main');
require('_fil/global-cms-card/main');
// require('_fil/global-cms-marketo-form/marketo');
// require('_fil/global-cms-fund-data-tool/index');

//tools addon
require('_fil/global-cms-tw-dynamic-list-content/main');
require('_fil/global-cms-tw-fund-wrapper/main');
require('_fil/global-cms-iss-article/index');
