import Cleave from 'cleave.js/dist/cleave-esm.min.js';

export const inputMask = (el, mask) => {
  const allowedMasks = [
    'number',
    'dob',
    'currency',
    'percentage',
    'nationalInsuranceNumber',
  ];
  if (!allowedMasks.includes(mask)) {
    console.warn(`Mask must be one of ${allowedMasks.join(', ')}`);
  }
  if (mask === 'number') {
    new Cleave(el, {
      numeral: true,
      stripLeadingZeroes: true,
      numeralThousandsGroupStyle: 'none',
    });
    return;
  }
  if (mask === 'dob') {
    new Cleave(el, {
      date: true,
      delimiter: ' - ',
      datePattern: ['d', 'm', 'Y'],
    });
    return;
  }
  if (mask === 'currency') {
    new Cleave(el, {
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: '£ ',
    });
    return;
  }
  if (mask === 'percentage') {
    new Cleave(el, {
      numeral: true,
      numeralPositiveOnly: true,
      numeralIntegerScale: 2,
    });
    return;
  }
  if (mask === 'nationalInsuranceNumber') {
    new Cleave(el, {
      delimiter: ' ',
      blocks: [2, 2, 2, 2, 1],
      uppercase: true,
    });
    el.addEventListener('keypress', (e) => {
      let start = e.target.selectionStart;
      const key = e.key.toLowerCase();
      const rawvalue = e.target.value.replace(/ /g, '');
      if (key.length !== 1) {
        return;
      }
      if (start > rawvalue.length) {
        start = rawvalue.length;
      }
      const isC = key >= 'a' && key <= 'z';
      const isN = key >= '0' && key <= '9';
      const pattern = [isC, isC, isN, isN, isN, isN, isN, isN, isC];
      if (!pattern[start]) {
        e.preventDefault();
      }
    });
    return;
  }
};
