import * as CONSTANTS from './fund-search-consts';

export function getFundName(fund, language_tag = CONSTANTS.FUND_NAME_LANGUAGETAG_TW) {
  const shareClassNames_props = fund.shareclassFacets && fund.shareclassFacets.shareclassName;
  const fund_name_props = fund.fundName;
  const fundOriginalNameProps = fund.fundOriginalName;
  let fund_name = '';
  if (shareClassNames_props) {
    fund_name = shareClassNames_props.find(it => it.languageTag == language_tag).name;
  } else if (fund_name_props) {
    fund_name = fund_name_props.find(it => it.languageTag == language_tag).name;
  }

  if (fundOriginalNameProps) {
    const fundOriginalNameProp = fundOriginalNameProps.find(it => it.languageTag == language_tag);
    if (fundOriginalNameProp) {
      const fundOriginalName = fundOriginalNameProp.name;
      if (fundOriginalName) {
        fund_name = `${fund_name}(${fundOriginalName})`;
      }
    }
  }

  return fund_name;
}

export function getFundUrl(fund) {
  const shareClassNames_props = fund.shareclassFacets && fund.shareclassFacets.shareclassName;
  let fund_url = '';
  if (shareClassNames_props) {
    const en_fund_name = shareClassNames_props.find(
      it => it.languageTag == CONSTANTS.FUND_NAME_LANGUAGETAG_EN
    ).name;
    const formattedName = replaceSpecialChars(
      en_fund_name,
      CONSTANTS.FUND_NAME_SEP_CHAR,
      CONSTANTS.REGEX_FUND_NAME
    );
    fund_url = `/${CONSTANTS.FUND_ITEM_URL_PREFIX}/${formattedName}`;
  }

  return fund_url;
}

export function formatFundHTML(fund) {
  return CONSTANTS.DROPDOWN_ITEM_HTML.replace(/\$\{fund_name\}/g, getFundName(fund))
    .replace(/\$\{fund_url\}/g, getFundUrl(fund))
    .replace(/\$\{isin\}/g, fund.shareclassFacets ? fund.shareclassFacets.isin : '')
    .replace(/\$\{target\}/g, CONSTANTS.OPEN_LINK_IN_NEW_TAB ? '_blank' : '_self');
}

export function replaceSpecialChars(originString, target = '-', regexp = CONSTANTS.REGEX_GENERIC) {
  return originString
    .replace(regexp, target)
    .split(target)
    .filter(item => item !== '')
    .join(target);
}

/*
 * matchKeywordInString('a-long-string', 'search', false, false, [['aa,'bb'],['cc','dd']])
 * strict:
 * false - non-strict match
 * true  - strict full match, case sensitive
 * caseless:
 * false - case sensitive
 * true - caseless
 * tokenizers_list:
 * array of tokenizers(array), need to tokenize the search string and use to match the hay string.
 */
export function matchKeywordInString(
  hay,
  str,
  strict = true,
  caseless = true,
  tokenizers_list = []
) {
  let bingo = false;

  if (caseless) {
    hay = hay.toLowerCase();
    str = str.toLowerCase();
  }

  if (strict) {
    bingo = hay == str;
  } else {
    if (tokenizers_list && tokenizers_list.length) {
      bingo = tokenizer_helper(hay, str, tokenizers_list);
    } else {
      bingo = hay.includes(str);
    }
  }

  return bingo;
}

/*
 * tokenizer_helper('a-long-string', 'search', [['aa,'bb'],['cc','dd']])
 * tokenizers_list:
 * array of tokenizers(array), need to tokenize the search string and use to match the hay string.
 */
function tokenizer_helper(hay, str, tokenizers_list = []) {
  const splitted_search = tokenizers_list.map(token => {
    const regex = new RegExp(`(${token.join(')|(')})`);
    return str.split(regex).filter(Boolean);
  });

  return splitted_search.some(search => {
    const regEx = new RegExp(search.join('.*'));
    return regEx.test(hay);
  });
}

export function formatKeyWord(keyword) {
  return keyword.trim();
}
