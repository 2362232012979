const ListPaginator = require('./list_paginator');

$(function () {
  $('[data-paginator="list"]').each((i, el) => {
    const paginator = new ListPaginator(el, {
      disableSEO: i > 0,
      disableURL: i > 0
    });

    paginator.rebuild();
  });
});
