import { ArticleSections } from '_fil/global-cms-article-base/article_section_list';

$(function(){
  const $content = $('.global-cms-iss-article .article-main-content');

  if (!$content.length) {
    return;
  }

  const sections = new ArticleSections($content);
  const $sectionList = $content.find('.content-navigation ul');

  sections.$headers = $content.find('.article-content-wrapper h4:not(.article_author_name)');
  sections.createList($sectionList);

  if(sections.$headers.length == 0){
    const $emptySectionWrapper = $sectionList.parent().hide();
    $emptySectionWrapper.next('.fil-text-container').addClass('no-mobile-padding-top');
    $emptySectionWrapper.nextAll('.fil-article-list').addClass('no-padding-top');
  }

});
