/* global Foundation */
import { insertSectionsList } from './article_section_list';
const ArticlePaginator = require('./article_paginator');

$(document).ready(function() {
  // Lazy loading main.css causes issues for Foundation's equalizer. Force
  // re-initialisation after the styling has finished loading.
  if (window.mainCSSLoader && $('.fil-article-grid').length) {
    //window.mainCSSLoader.addEventListener('load', () => {
    //  Foundation.reInit('equalizer');
    //});
  }

  // Initialise the ArticlePaginator for each paginator in the page. The first
  // paginator takes priority for handling SEO and push state.
  $('[data-paginator="article"]').each((i, el) => {
    const paginator = new ArticlePaginator(el, {
      disableSEO: i > 0,
      disableURL: i > 0
    });
    paginator.rebuild();
  });

  // Social sharing
  const pageTitle = encodeURIComponent($('meta[property=\'og:title\']').attr('content'));
  const pageURL = encodeURIComponent(document.location.href);
  let linkedinURL = encodeURIComponent($('meta[name=\'linkedin:url\']').attr('content'));
  let facebookURL = encodeURIComponent($('meta[name=\'facebook:url\']').attr('content'));
  let twitterURL = encodeURIComponent($('meta[name=\'twitter:url\']').attr('content'));
  let mailURL = encodeURIComponent($('meta[name=\'mail:url\']').attr('content'));

  $('.social-sharing .fil-icon-logo-linkedin').click(function() {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${pageURL}${linkedinURL}&title=${pageTitle}`
    );
  });

  $('.social-sharing .fil-icon-messages-filled').click(function() {
    window.open(`mailto:?subject=${pageTitle}&body=${pageURL}${mailURL}`);
  });

  $('.social-sharing .fil-icon-logo-facebook').click(function() {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${pageURL}${facebookURL}`);
  });

  $('.social-sharing .fil-icon-logo-twitter').click(function() {
    window.open(`https://twitter.com/share?url=${pageURL}${twitterURL}&text=${pageTitle}`);
  });
  insertSectionsList();
});
